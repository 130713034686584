import Markdown from "react-markdown";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import Lenis from "lenis";
import { cn } from "~/lib/utils";
import { useEffect } from "react";

type SlideItem = {
  title: string | undefined | null;
  description: string | undefined | null;
  image?: string | undefined | null;
};

export const VerticalServicesSlide = ({
  className,
  index,
  slide,
  children,
}: {
  className?: string;
  index: number;
  slide: SlideItem;
  children?: React.ReactNode;
}) => {
  return (
    <div
      key={index}
      className={cn(
        "s-card [&:is(:first-child)]:portrait:pt-6 [&:not(:last-child)]:h-dvh [&:not(:last-child)]:sticky top-0 flex flex-col justify-center [&:not(:last-child)]:pt-12 [&:not(:last-child)]:landscape:pt-0",
        className
      )}
    >
      <div className="page relative">
        <div className="grid landscape:grid-cols-2 gap-4 landscape:gap-20">
          <div>
            <h3 className="portrait:text-[1.6rem] landscape:text-[2.625rem] portrait:mb-0 landscape:mb-2">
              {index < 10 ? `0${index + 1}` : index + 1}
            </h3>
            <h2 className="portrait:text-[1.6rem] landscape:text-[2.625rem] landscape:w-1/2">
              {slide.title}
            </h2>
            <Markdown
              components={{
                p: ({ children }) => <p className="mb-6">{children}</p>,
                strong: ({ children }) => (
                  <strong className="font-medium">{children}</strong>
                ),
                ul: ({ children }) => (
                  <ul className="list-disc list-outside mb-6 ml-6">
                    {children}
                  </ul>
                ),
                li: ({ children }) => <li className="mb-2">{children}</li>,
                a: ({ children, href }) => (
                  <a
                    href={href}
                    className="text-secondary hover:underline"
                    rel="noreferrer"
                  >
                    {children}
                  </a>
                ),
              }}
            >
              {slide.description}
            </Markdown>
          </div>
          <div className="s-img">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const VerticalServicesSlider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useEffect(() => {
    const mm = gsap.matchMedia();
    mm.add("(min-width: 0px)", () => {
      gsap.registerPlugin(ScrollTrigger);
      const elements = [...document.querySelectorAll(".s-card")];
      const totalElements = elements.length;

      const lenis = new Lenis({
        lerp: 0.2,
        smoothWheel: true,
        syncTouch: true,
      });

      lenis.on("scroll", ScrollTrigger.update);

      gsap.ticker.add((time: any) => {
        lenis.raf(time * 1000);
      });

      gsap.ticker.lagSmoothing(0);

      elements.forEach((el, position) => {
        const isLast = position === totalElements - 1;
        gsap
          .timeline({
            scrollTrigger: {
              trigger: el,
              start: "top top",
              end: "+=100%",
              scrub: true,
            },
          })
          .fromTo(
            el,
            {
              ease: "none",
              filter: "brightness(100%) contrast(100%)",
              yPercent: 0,
            },
            {
              filter: isLast ? "none" : "brightness(60%) contrast(135%)",
              yPercent: isLast ? 0 : -15,
            }
          );
      });
    });
  }, []);

  return <div className="relative">{children}</div>;
};
